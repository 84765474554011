<template>
  <a-card :bordered="false">
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
          <a-col :md="6" :sm="12">
            <a-form-item label="商品分组">
              <a-select
                  placeholder="请选择商品分组"
                  show-search
                  option-filter-prop="children"
                  style="width: 100%"
                  :filter-option="filterOption"
                   v-model="queryParam.goodsGroupId"
                  :allowClear="true" 
                >
                  <a-select-option  v-for=" item in shopGroupList" :key="item.id" :value="item.id">
                    {{item.groupNameZh}}
                  </a-select-option>
                </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="12">
            <a-form-item label="商品名称(中文)">
              <a-input
                :allowClear="true" 
                :maxLength="240" 
                placeholder="请输入商品名称(中文)"
                v-model="queryParam.goodsNameZh"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="12">
            <a-form-item label="商品标签">
             <a-select  
                   style="width: 100%" 
                   placeholder="请选择商品分组"
                   v-model="queryParam.shopTag"
                   :allowClear="true" >
                  <a-select-option  value="none">
                    无标签
                  </a-select-option> 
                  <a-select-option  value="recommend">
                    精品推荐
                  </a-select-option>
                  <a-select-option  value="minPrice">
                    低价促销
                  </a-select-option>
                </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="12">
            <a-form-item label="是否上架">
              <a-select  
                   style="width: 100%" 
                   placeholder="请选择是否上架"
                   v-model="queryParam.status"
                   :allowClear="true" >
                  <a-select-option  value="1">
                    是
                  </a-select-option> 
                  <a-select-option  value="0">
                    否
                  </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="12">
            <a-form-item label="是否含有规格">
              <a-select  
                   style="width: 100%" 
                   placeholder="请选择是否含有规格"
                   v-model="queryParam.isHaveSpecs"
                   :allowClear="true" >
                  <a-select-option  value="1">
                    是
                  </a-select-option> 
                  <a-select-option  value="0">
                    否
                  </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
           <a-col :md="8" :sm="12">
            <a-form-item label="商品现价金额区间">
              <a-input-number placeholder="现价金额起"  v-model="queryParam.startRealMoney" :min="0" :max="9999999999" :precision="2" />
                <span style="margin-left:10px;margin-right:10px"> ~  </span>
              <a-input-number placeholder="现价金额止"    v-model="queryParam.endRealMoney" :min="0" :max="9999999999" :precision="2"  />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <span
              style="float: left; overflow: hidden"
              class="table-page-search-submitButtons"
            >
              <a-button type="primary" @click="searchQuery" icon="search"
                >查询</a-button
              >
              <a-button
                type="primary"
                @click="searchReset"
                icon="reload"
                style="margin-left: 8px"
                >重置</a-button
              >
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <!-- 查询区域-END -->

    <!-- 操作按钮区域 -->
    <div class="table-operator">
      <a-button @click="handleAdd" type="primary" icon="plus">新增</a-button>
    </div>

    <!-- table区域-begin -->
    <div>
      <div class="ant-alert ant-alert-info" style="margin-bottom: 16px;">
        <a-icon style="margin-left:20px;margin-right:10px;color:#EA7500" type="info-circle" />
           注意: 1.如果商品创建了规格则<span style="color:red">无法重置</span>为无规格商品  2.当实际库存低于10件时,会显示<span style="color:red">红色</span> 
      </div>

      <a-table
        ref="table"
        size="middle"
        :scroll="{x:1200}"
        bordered
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        class="j-table-force-nowrap"
        @change="handleTableChange">
          <template slot="oldMoney" slot-scope="text">
            <div color="red">
            {{text}} 元
            </div>
          </template>
          <template slot="realMoney" slot-scope="text, record">
            <a-tag color="green" v-if="!record.haveSpecs">
               {{record.realMoney}} 元
            </a-tag>
            <div  v-else>
               ——
            </div>
          </template>
          <template slot="stock" slot-scope="text, record">
            <div color="blue" v-if="!record.haveSpecs">
              {{record.shopGoodsSpecsList[0].stock}} 件
            </div>
            <div  v-else>
               ——
            </div>
          </template>
          <template slot="rotatImg" slot-scope="text, record">
            <a @click="showImg(record.rotatImgList)">查看轮播图</a>
          </template>
          <template slot="goodsDetailsImg" slot-scope="text, record">
            <a @click="showImg(record.goodsDetailsImgList)">查看详情图</a>
          </template>
        
        <template slot="status" slot-scope="text, record">
          <template  v-if="record.status == 1">
            <a-switch checked-children="已上架"  :checked="true" :loading="switchLoading" @click="batchChangeOnline(record,0)" />
          </template>
          <template  v-else>
            <a-switch un-checked-children="未上架" :checked="false" :loading="switchLoading" @click="batchChangeOnline(record,1)"/>
          </template>
        </template>


        <template slot="shopTag" slot-scope="text, record">
            <a-tag color="#108ee9" v-if="record.shopTag">
              {{record.shopTagName}}
            </a-tag>
            <template v-else>无</template>
        </template>

        <template slot="profit" slot-scope="text, record">
          <template v-if="record.shopGoodsSpecsList[0].isDefault == '0'">
              {{record.shopGoodsSpecsList[0].profit}} 元
          </template>
          <template v-else>
              见规格详情
          </template>
        </template>
        
        <template slot="couponMoney" slot-scope="text, record">
           <template v-if="record.couponHappen == 1">
             <a-tag color="cyan">  {{ text }} 元</a-tag>
             <div style="margin-top: 5px;">
               <a-tag color="orange"> 进入页面触发</a-tag>
             </div>
           </template>
           <template v-if="record.couponHappen == 2">
             <a-tag color="cyan">
                <span v-for="item in record.leaveMoneyList" :key="item"> {{ item }}元</span>
              </a-tag>
             <div style="margin-top: 5px;">
               <a-tag color="red"> 离开页面触发{{ record.leaveNum }} 次</a-tag>
             </div>
           </template>
          <template v-else>
              -
          </template>
         
        </template>


        <template slot="shopSpecs" slot-scope="text, record">
          <template v-if="record.shopGoodsSpecsList[0].isDefault == '0'">
              暂无规格
          </template>
          <template v-else>
            <a @click="showShopSpecs(record)">点击查看规格</a>
          </template>
        </template>
          
          <span slot="action" slot-scope="text, record">
            <a @click="addShopSpecs(record)">创建规格</a>
            <a-divider type="vertical" />
            <a-dropdown>
              <a class="ant-dropdown-link">更多<a-icon type="down" /></a>
              <a-menu slot="overlay">
                 <a-menu-item>
                  <a @click="tempUpdate(record)">详情</a>
                </a-menu-item>
                <a-menu-item v-if="record.shopGoodsSpecsList[0].isDefault == '0'">
                  <a @click="changeStock(record)">补充库存</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="setSaleCount(record)">设置销量</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="setCoupon(record)">设置优惠券</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="setUserVipCoupon(record)">设置专享优惠链接</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="getBottomPath(record)">查看落地页链接</a>
                </a-menu-item>
                 <a-menu-item>
                  <a @click="tempUpdate(record)">编辑</a>
                </a-menu-item>
                <a-menu-item>
                  <a-popconfirm title="删除后无法恢复,确定删除吗?" @confirm="() => handleDelete(record.id)">
                    <a>删除</a>
                  </a-popconfirm>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
      </a-table>
    </div>

    <shop-goods-form ref="realForm" @ok="submitCallback"></shop-goods-form>
    <img-show-modal ref="imgShowModal" @ok="submitCallback" />
    <shop-specs-modal ref="shopSpecsModal" @refeshData="refeshData" />
    <update-sale-count ref="updateSaleCount" @ok="submitCallback" />
    <add-shop-specs-form ref="addShopSpecsForm" @ok="submitCallback"  />
    <change-stock-modal ref="changeStockModal" @ok="submitCallback" />
    <update-shop-modal ref="updateShopModalForm" @ok="submitCallback" />
    <shop-coupon-modal ref="shopCouponModalForm" @ok="submitCallback" />
    <shop-user-vip-coupon-modal  ref="shopUserVipCouponModalForm" @ok="submitCallback"  />
    <shop-show-bottom-modal ref="shopShowBottomModalForm" @ok="submitCallback"></shop-show-bottom-modal>
  </a-card>
</template>

<script>

  import '@/assets/less/TableExpand.less'
  import { mixinDevice } from '@/utils/mixin'
  import { CustomerShopMixin } from '@/mixins/CustomerShopMixin'
  import ShopGoodsForm from './modules/ShopGoodsForm'
  import ImgShowModal from '@/components/common/ImgShowModal'
  import { USER_INFO,ACCESS_TOKEN } from '@/store/mutation-types'
  import Vue from 'vue'
  import { Common } from "@/apis";
  import ShopSpecsModal from './modules/ShopSpecsModal.vue'
  import AddShopSpecsForm from './modules/AddShopSpecsForm.vue'
  import { httpAction, postAction } from '@/api/manage'
  import ChangeStockModal from './modules/ChangeStockModal.vue'
  import UpdateShopModal from './modules/UpdateShopModal.vue'
  import UpdateSaleCount from './modules/UpdateSaleCount.vue'
  import ShopCouponModal from './modules/ShopCouponModal.vue'
  import ShopUserVipCouponModal from './modules/ShopUserVipCouponModal.vue'
  import ShopShowBottomModal from './modules/ShopShowBottomModal.vue'
  import { validateDuplicateValue,isEmpty } from '@/utils/commonUtil'

  export default {
    name: 'ShopGoodsList',
    mixins:[CustomerShopMixin, mixinDevice],
    components: {
      ShopGoodsForm,
      ImgShowModal,
      ShopSpecsModal,
      AddShopSpecsForm,
      ChangeStockModal,
      UpdateSaleCount,
      UpdateShopModal,
      ShopUserVipCouponModal,
      ShopShowBottomModal,
      ShopCouponModal
    },
    data () {
      return {
        description: '商品管理页面',
        switchLoading:false,
        // 表头
        columns: [
          {
            title:'排序',
            align:"center",
            dataIndex: 'sort',
            ellipsis: true,
            width:100,
          },
          {
            title:'商品名称',
            align:"center",
            dataIndex: 'goodsNameZh',
            ellipsis: true,
            width:300,
          },
          {
            title:'商品规格',
            align:"center",
            dataIndex: '',
            ellipsis: true,
            width:150,
            scopedSlots: { customRender: 'shopSpecs' },
          },
          {
            title:'商品现价（元）',
            align:"center",
            dataIndex: 'realMoney',
            scopedSlots: { customRender: 'realMoney' },
            ellipsis: true,
            width:120,
          },
         
          {
            title:'是否上架',
            align:"center",
            dataIndex: 'status',
            scopedSlots: { customRender: 'status' },
            ellipsis: true,
            width:120,
          },
          {
            title:'实际库存剩余量',
            align:"center",
            dataIndex: 'realStock',
            // scopedSlots: { customRender: 'realStock' },
            ellipsis: true,
            width:120,
            customRender : (values,res) => {
              let maxNum = 9999999999;
              let data = res.shopGoodsSpecsList
              for(let i in data){
                let d = data[i]
                if(d.realStock == null){
                  d.realStock = 0;
                }
                if(d.realStock <= maxNum){
                    maxNum = d.realStock
                }
              }
              
              if(maxNum <= 10){
                return <span style="color:red">{maxNum} 件</span>;
              }
              return <span style="color:green">{maxNum} 件</span>;
            }
          },
          {
            title:'初始库存量',
            align:"center",
            dataIndex: 'stock',
            scopedSlots: { customRender: 'stock' },
            ellipsis: true,
            width:120,
          },{
            title:'优惠券情况',
            align:"center",
            dataIndex: 'couponMoney',
            scopedSlots: { customRender: 'couponMoney' },
            ellipsis: true,
            width:140,
          },
          {
            title:'销量',
            align:"center",
            dataIndex: 'saleCount',
            ellipsis: true,
            width:120,
          },
          {
            title:'商品服务描述(中文)',
            align:"center",
            dataIndex: 'serviceDescZh',
            ellipsis: true,
            width:250,
          },
          {
            title:'商品分组',
            align:"center",
            dataIndex: 'groupNameZh',
            ellipsis: true,
            width:150,
          },
          {
            title:'商品原价（元）',
            align:"center",
            dataIndex: 'oldMoney',
            scopedSlots: { customRender: 'oldMoney' },
            ellipsis: true,
            width:120,
          },
          {
            title:'商品轮播图地址',
            align:"center",
            dataIndex: 'rotatImg',
            ellipsis: true,
            width:120,
            scopedSlots: { customRender: 'rotatImg' },
          },
          {
            title:'商品详情图',
            align:"center",
            dataIndex: 'goodsDetailsImg',
            ellipsis: true,
            width:120,
            scopedSlots: { customRender: 'goodsDetailsImg' },
          },
        
          {
            title:'商品成本（元）',
            align:"center",
            dataIndex: 'profit',
            ellipsis: true,
            width:140,
            scopedSlots: { customRender: 'profit' },
          },
          {
            title:'商品id',
            align:"center",
            dataIndex: 'id',
            ellipsis: true,
            width:180,
          },
          {
            title:'商品创建时间',
            align:"center",
            dataIndex: 'createTime',
            ellipsis: true,
            width:180,
          },
          {
            title: '操作',
            dataIndex: 'action',
            align:"center",
            fixed:"right",
            width:160,
            scopedSlots: { customRender: 'action' }
          },
        ],
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
        innerColumns:[
          {
            title:'',
            align:"left",
            dataIndex: 'specsNameZh',
            ellipsis: true,
            width:150,
            scopedSlots: { customRender: 'innerData' },
          },
        ],
        innerData:[],
        url: {
          list: "/shop/web/shop/getShopList",
           // 上下架
          changeOnline: "/shop/web/shop/changeOnline",
          deleteShop: "/shop/web/shop/deleteShop",
        },
        dictOptions:{},
        superFieldList:[],
        shopGroupList:[],
        accessToken:''
      }
    },
    created() {
      const userinfo = Vue.ls.get(USER_INFO)
      const accessToken  = Vue.ls.get(ACCESS_TOKEN)
      this.accessToken = accessToken
      this.openLanguage = userinfo.openLanguage
      this.getShopGroupNoPage()
    },
    computed: {
  
    },
    methods: {
      initDictConfig(){
      },
      // 添加商品
      handleAdd(){
        let param = {
           openLanguage : this.openLanguage,
           shopGroupList: this.shopGroupList
        }
        this.$refs.realForm.add(param);
      },
      // 临时调整
      tempUpdate(record){
        record.openLanguage = this.openLanguage
        record.shopGroupList = this.shopGroupList
        this.$refs.updateShopModalForm.show(record);
      },
      // 修改商品
      handleEdit(record){
        record.openLanguage = this.openLanguage
        this.$refs.realForm.edit(record);
      },
      setSaleCount(record){
        this.$refs.updateSaleCount.show(record);
      },
      showImg(url){
        let param = {
          url:url
        }
        this.$refs.imgShowModal.show(param);
      },
      showShopSpecs(record){
        record.accessToken = this.accessToken
        record.openLanguage = this.openLanguage
        this.$refs.shopSpecsModal.show(record);
      },
      addShopSpecs(record){
        record.openLanguage = this.openLanguage
        this.$refs.addShopSpecsForm.add(record);
      },
      changeStock(record){
         this.$refs.changeStockModal.add(record.shopGoodsSpecsList[0]);
      },
      batchChangeOnline(record,status){
        let param = {
           id : record.id,
           status:status
        }
        var that = this;
        this.$confirm({
          title: "确认上下架",
          content: "上架会初始化库存为设置库存,下架会清空库存,确认是否要上架或下架?",
          onOk: function () {
            that.switchLoading = true;
            postAction(that.url.changeOnline,param).then((res)=>{
                if(res.code == 200){
                  that.submitCallback();
                  that.$message.success("操作成功");
                }else{
                  that.$message.error(res.message);
                }
              }).finally(() => {
                that.switchLoading = false;
              })
           }
        });
      },
      getShopGroupNoPage(){
          Common.getShopGroupNoPage({}).then((res) => {
            if (res.code ==200) {
               this.shopGroupList = res.data
            } else {
              this.$message.error(res.message);
            }
         }) 
      },
      // 设置优惠券
      setCoupon(record){
        this.$refs.shopCouponModalForm.show(record);
      },
      // 设置专享优惠链接
      setUserVipCoupon(record){
        this.$refs.shopUserVipCouponModalForm.show(record);
      },
      getBottomPath(record){
        this.$refs.shopShowBottomModalForm.show(record); 
      },
      // 删除商品
      handleDelete(id){
          let that = this
          let param = {
            id:id
          }
          this.loading = true
          postAction(this.url.deleteShop,param).then((res)=>{
            if(res.code == 200){
              that.$message.success("删除商品成功");
              that.submitCallback();
            }else{
              that.$message.error(res.message);
            }
          }).finally(() => {
            that.loading = false;
          })
      },
      submitCallback(){
        this.loadData();
      },
      filterOption(input, option) {
        return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
        );
      },
      // 刷新数据
      refeshData(res){
        let data = this.dataSource
         for(let i in data){
            let d = data[i]
            if(d.id == res[0].goodsId){
               d.shopGoodsSpecsList = res
            }
         }
      }
    }
  }
</script>
<style scoped>
  @import '~@assets/less/common.less';
</style>