<template>
    <a-modal
      :title="title"
      :width="width"
      :visible="visible"
      switchFullscreen
      @ok="submitForm"
      @cancel="handleCancel"
      :confirmLoading="confirmLoading"
      okText="设置淘宝链接"
      cancelText="关闭">
          <a-form  :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16}">
            <a-row>
              <a-col :span="24">
                <a-form-item label="淘宝链接">
                  <a-input :maxLength="200" v-decorator="['locationThreeUrl', { rules: [{ required: true, message: '请输入淘宝链接!' }] }]"  placeholder="请输入淘宝链接"  ></a-input>
                  </a-form-item>
              </a-col>
              <a-col :span="24">
              <a-form-item label="落地页主图" v-if="!record.bottomImg">
                <a-upload
                      v-decorator="['imgFile', { rules: [{ required: true, message: '请上传落地页主图!' }] }]"
                      :file-list="fileList"
                      :before-upload="beforeUpload"
                      accept=".png,.jpg"
                    >
                  <div>
                      <a-button type="primary"> <a-icon type="upload" />上传</a-button>
                      <a-icon style="margin-left:20px;margin-right:10px;color:#EA7500" type="info-circle" />注意: 落地页主图多上传1个
                  </div>
               </a-upload>
              </a-form-item>
              <a-form-item label="落地页主图" v-else>
                <img :src="record.bottomImg" width="300px" height="300px" />
                <br/>
                <a-upload
                      v-decorator="['imgFile', { rules: [{ required: false, message: '请上传落地页主图!' }] }]"
                      :file-list="fileList"
                      :before-upload="beforeUpload"
                      accept=".png,.jpg"
                    >
                  <div style="margin-top: 20px;">
                      <a-button type="primary"> <a-icon type="upload" />修改</a-button>
                  </div>
               </a-upload>
              </a-form-item>
            </a-col>
              <a-col :span="24">
                <a-form-item label="落地页链接">
                    <div v-if="record.locationThreeUrl == null || record.locationThreeUrl == ''">请先设置淘宝链接</div>
                    <div v-else>https://域名/pages/bottom/ShopDetails?id={{ idStr }}</div>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
    </a-modal>  
    
</template>

<script>

  import { postAction, uploadAction } from '@/api/manage'
  import { validateDuplicateValue,isEmpty } from '@/utils/commonUtil'
  // 设置专享优惠链接
  export default {
    name: 'ShopUserVipCouponModal',
    components: {
    },
    data () {
      return {
        title:'',
        width:800,
        visible: false,
        resultVisible:false,
        openLanguage:false,
        confirmLoading: false,
        resultText:'',
        record:{},
        fileList: [],
        idStr:"",

        form: this.$form.createForm(this, { name: 'coordinated' }),
        url: {
          add: "",
          edit: "",
          genUserShopCoupon:'/shop/web/shop/genUserShopCoupon'
        }
      }
    },
    computed: {
    
    },
    created () {
    },
    methods: {
      show (record) {
        console.log(record)
        this.record = record;
        this.$nextTick(() => {
            setTimeout(() => {
              this.form.setFieldsValue({
                locationThreeUrl: record.locationThreeUrl,
              })
            })
        });
        this.idStr = encodeURIComponent(record.idStr) ;
        this.title = "落地页: " + record.goodsNameZh
        this.visible = true;
      },
      submitForm () {
        let that = this;
        this.form.validateFields((err, values) => {
          if (!err) {
              const formData = new FormData();
              for(let i in values){
               let v = values[i]
               if(!isEmpty(v)){
                 if(v.fileList){
                    v.fileList.forEach(file => {
                      if(file.originFileObj){
                          formData.append(i, file.originFileObj);
                      }else{
                          formData.append(i, file);
                      }
                      
                    });
                 }else{
                    formData.append(i, v);
                 }
               }
             }
              formData.append('goodsId', that.record.id);
              that.confirmLoading = true
              uploadAction('/shop/web/shop/setLocationThreeUrl' ,formData).then((res)=>{
                if(res.code == 200){
                   this.record.locationThreeUrl = res.data.locationThreeUrl
                   this.record.bottomImg = res.data.readBottomImg
                   this.fileList = []
                }else{
                  that.$message.error(res.message);
                }
              }).finally(() => {
                that.confirmLoading = false;
              })
          }
         })
      },
      beforeUpload(file) {
        this.fileList = [];
        this.fileList = [...this.fileList, file];
        return false;
      },
      handleRemove(file) {
        this.fileList = [];
      },
      handleCancel () {
        this.visible = false;
      }
    }
  }
</script>