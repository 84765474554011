<template>
  <a-card :bordered="false">
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
          <a-col :md="6" :sm="12">
            <a-form-item label="退单号">
              <a-input-number 
                style="width: 100%" 
                placeholder="请输入退款单号"
                v-model="queryParam.queryRefundId"
                :allowClear="true"
                />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="12">
            <a-form-item label="状态">
             <a-select  
                style="width: 100%" 
                placeholder="请选择状态"
                v-model="queryParam.queryStatus"
                :allowClear="true" >
                    <a-select-option  value="processing">
                      待商家确认
                    </a-select-option> 
                    <a-select-option  value="agreed_change">
                      已同意换货
                    </a-select-option>
                    <a-select-option  value="refunding">
                      退款中
                    </a-select-option>
                    <a-select-option  value="refund_fail">
                      退款失败
                    </a-select-option>
                     <a-select-option  value="refunded">
                      已退款
                    </a-select-option>
                    <a-select-option  value="rejected">
                      已拒绝
                    </a-select-option>
                </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="12">
            <a-form-item label="申请类型">
             <a-select  
                   style="width: 100%" 
                   placeholder="请选择申请类型"
                   v-model="queryParam.applyType"
                   :allowClear="true" >
                    <a-select-option  value="change">
                        换货
                    </a-select-option>
                    <a-select-option  value="money">
                      退款
                    </a-select-option>
                    <a-select-option  value="onlyMoney">
                      仅退款
                    </a-select-option> 
                    <a-select-option  value="tenantMoney">
                      商家自主发起退款
                    </a-select-option> 
                </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <span
              style="float: left; overflow: hidden"
              class="table-page-search-submitButtons"
            >
              <a-button type="primary" @click="searchQuery" icon="search"
                >查询</a-button
              >
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <!-- 查询区域-END -->

    <!-- 操作按钮区域 -->
    <div class="table-operator">
    </div>
    <div>
         <a-tabs :default-active-key="refundStatus.processing" @change="changeRefundStatus">
            <a-tab-pane :key="refundStatus.processing" tab="售后中"></a-tab-pane>
            <a-tab-pane :key="refundStatus.complete" tab="已完成"></a-tab-pane>
          </a-tabs>
      </div>
    <!-- table区域-begin -->
    <div>

      <a-table
        ref="table"
        size="middle"
        :scroll="{x:1500}"
        bordered
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        class="j-table-force-nowrap"
        @change="handleTableChange">

        <template slot="shopInfo" slot-scope="text, record">
           <div :title="record.shopName">{{record.shopName}} </div>
           <div>
             共 <span style='color:red'> {{record.totalNum}} 件  </span>
             实付款: <span style='color:red'>{{record.applyMoney}} 元</span></div>
        </template>

        <template slot="applyType" slot-scope="text, record">
            <a-tag :color="text == 'change'? '#108ee9' : '#FF2D2D'">
              {{record.applyTypeZh}}
            </a-tag>
        </template>

        <template slot="receptName" slot-scope="text, record">
           <div>{{record.receptName}} {{record.receptPhone}}</div>
           <div :title="record.receptAddress">{{record.receptAddress}}</div>
        </template>

        <template slot="statusZh" slot-scope="text, record">
            <span v-if="record.status == 'processing' " style="color:#f1a532">{{text}}</span>
            <span v-else-if="record.status == 'agreed_change' || record.status == 'refunding' " style="color:#FF2D2D">{{text}}</span>
            <span v-else-if="record.status == 'refunded' " style="color:#87d068">{{text}}</span>
            <span v-else style="color:#FF2D2D">{{text}}</span>
        </template>

        

        <template slot="orderid" slot-scope="text, record">
          {{record.orderId}}
        </template>
         
        <template slot="orderDetailsId" slot-scope="text, record">
          {{record.orderDetailsId}}
        </template>

        <span slot="action" slot-scope="text, record">
          <a v-if="record.applyType == 'change' " @click="agreedChangeShow(record)">同意换货</a>
          <a v-else-if="record.applyType != 'tenantMoney' " @click="refundMoneyShow(record)">退款</a>
          <a-divider type="vertical" />
          <a v-if="record.applyType != 'tenantMoney' " @click="refuseApplyShow(record)">拒绝申请</a>
        </span>
      </a-table>
    </div>

    <!-- 拒绝申请 -->
    <a-modal
       title="拒绝申请"
       :visible="refuseVisible"
       :confirm-loading="modalLoading"
       @ok="refuseApply"
       @cancel="handleCancel"
       :width="600"
     >
       <div>
         <a-form  :form="refuseForm" :label-col="{ span: 5 }" :wrapper-col="{ span: 16}">
            <a-row>
              <a-col :span="24">
                <a-form-item label="商品信息">
                   {{refuseParam.shopInfo}}   <span style="color:red" v-if="refuseParam.specsInfo"> ({{refuseParam.specsInfo}}) </span>
                </a-form-item>
                <a-form-item label="商品金额">
                  {{refuseParam.money}} 元 ( 共{{refuseParam.count}}件 )
                </a-form-item>
                <a-form-item label="申请原因">
                  {{refuseParam.applyReason}}  
                </a-form-item>
                <a-form-item label="拒绝理由">
                  <a-input  
                      v-decorator="['refuseReason', { rules: [{ required: true, message: '请输入拒绝理由!' }] }]"
                        placeholder="请输入拒绝理由" style="width: 100%" />
                      <a-icon style="margin-right:10px;color:#EA7500" type="info-circle" />拒绝申请后,本单用户则无法再次申请售后,请慎重操作
                  </a-form-item>
              </a-col>
            </a-row>
         </a-form>
       </div>
    </a-modal>


    <!-- 同意换货 -->
    <a-modal
       title="同意换货"
       :visible="agreedVisible"
       :confirm-loading="modalLoading"
       @ok="agreedChange"
       @cancel="handleCancel"
       :width="600"
     >
       <div>
         <a-form  :form="agreedForm" :label-col="{ span: 5 }" :wrapper-col="{ span: 16}">
            <a-row>
              <a-col :span="24">
                <a-form-item label="商品信息">
                   {{refuseParam.shopInfo}}   <span style="color:red" v-if="refuseParam.specsInfo"> ({{refuseParam.specsInfo}}) </span>
                </a-form-item>
                <a-form-item label="商品金额">
                  {{refuseParam.money}} 元 ( 共{{refuseParam.count}}件 )
                </a-form-item>
                <a-form-item label="申请原因">
                  {{refuseParam.applyReason}}  
                </a-form-item>
                <a-form-item label="备注">
                  <a-input  
                      v-decorator="['refuseReason', { rules: [{ required: false, message: '请输入备注!' }] }]"
                        placeholder="请输入备注" style="width: 100%" />
                  </a-form-item>
              </a-col>
            </a-row>
         </a-form>
       </div>
    </a-modal>

     <!-- 退款 -->
    <a-modal
       title="退款"
       :visible="refundVisible"
       :confirm-loading="modalLoading"
       @ok="refundMoney"
       @cancel="handleCancel"
       :width="600"
     >
       <div>
         <a-form  :form="refundForm" :label-col="{ span: 5 }" :wrapper-col="{ span: 16}">
            <a-row>
              <a-col :span="24">
                <a-form-item label="商品信息">
                   {{refuseParam.shopInfo}}   
                </a-form-item>
                <a-form-item label="商品金额">
                  {{refuseParam.money}} 元 ( 共{{refuseParam.count}}件 )
                </a-form-item>
                <a-form-item label="申请原因">
                  {{refuseParam.applyReason}}  
                </a-form-item>
                <a-row>
                  <a-col :span="24">
                    <a-form-item label="退款金额">
                      <a-input-number :max="refuseParam.money" :min="0.01" :precision="2"
                          v-decorator="['refundMoney', { rules: [{ required: true, message: '请输入退款金额!' }]}]"
                            placeholder="请输入退款金额" style="width: 100%" />
                      <a-icon style="margin-right:10px;color:#EA7500" type="info-circle" />本单仅可操作一次退款,退款后无法再次退款 
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-form-item label="操作密码">
                  <a-input-password 
                      v-decorator="['refundPwd', { rules: [{ required: true, message: '请输入操作密码!' }] }]"
                        placeholder="请输入操作密码" style="width: 100%" />
                  </a-form-item>
                <a-form-item label="备注">
                  <a-input  
                      v-decorator="['refuseReason', { rules: [{ required: false, message: '请输入备注!' }] }]"
                        placeholder="请输入备注" style="width: 100%" />
                  </a-form-item>
              </a-col>
            </a-row>
         </a-form>
       </div>
    </a-modal>

  </a-card>
</template>
 
<script>

  import '@/assets/less/TableExpand.less'
  import { mixinDevice } from '@/utils/mixin'
  import { CustomerShopMixin } from '@/mixins/CustomerShopMixin'
  import { postAction, getAction } from '@/api/manage'
  // 售后管理
  export default {
    name: 'RefundList',
    mixins:[CustomerShopMixin, mixinDevice],
    components: {
    },
    data () {
      return {
        description: '售后关联页面',
        refuseVisible:false,
        agreedVisible:false,
        refundVisible:false,
        modalLoading:false,
        refuseForm: this.$form.createForm(this, { name: 'coordinated' }),
        agreedForm: this.$form.createForm(this, { name: 'agreedForm' }),
        refundForm: this.$form.createForm(this, { name: 'refundForm' }),
        // 拒绝、 同意换货 参数
        refuseParam:{},
        refundStatus:{
           processing:'processing',
           complete:'complete'
        },
        // 表头
        columns: [
          {
            title:'退单号',
            align:"center",
            dataIndex: 'id',
            ellipsis: true,
            width:180,
          },
         
          {
            title:'商品信息',
            align:"center",
            dataIndex: 'shopName',
            ellipsis: true,
            width:380,
            scopedSlots: { customRender: 'shopInfo' }
          },
          {
            title:'状态',
            align:"center",
            dataIndex: 'statusZh',
            ellipsis: true,
            width:150,
            scopedSlots: { customRender: 'statusZh' }
          },
          {
            title:'申请类型',
            align:"center",
            dataIndex: 'applyType',
            ellipsis: true,
            width:150,
            scopedSlots: { customRender: 'applyType' }
          },
          {
            title:'申请原因',
            align:"center",
            dataIndex: 'applyReason',
            ellipsis: true,
            width:250,
          },
          {
            title:'收货人信息',
            align:"center",
            dataIndex: 'receptName',
            ellipsis: true,
            width:250,
            scopedSlots: { customRender: 'receptName' }
          },
           {
            title:'申请时间',
            align:"center",
            dataIndex: 'createTime',
            ellipsis: true,
            width:150,
          },
          {
            title:'商家实退金额（元）',
            align:"center",
            dataIndex: 'realRefundMoney',
            ellipsis: true,
            width:150,
          },
          {
            title:'完成时间',
            align:"center",
            dataIndex: 'completeTime',
            ellipsis: true,
            width:150,
          },
          {
            title:'处理人信息',
            align:"center",
            dataIndex: 'refundUserAccount',
            ellipsis: true,
            width:150,
          },
          {
            title:'备注/商家拒绝原因',
            align:"center",
            dataIndex: 'refuseReason',
            ellipsis: true,
            width:250,
          },
          {
            title:'订单号',
            align:"center",
            dataIndex: 'deleteStatus',
            ellipsis: true,
            width:180,
            scopedSlots: { customRender: 'orderid' }
          },
          {
            title:'子单号',
            align:"center",
            dataIndex: 'tenantId',
            ellipsis: true,
            width:180,
             scopedSlots: { customRender: 'orderDetailsId' }
          },
          {
            title: '操作',
            dataIndex: 'action',
            align:"center",
            fixed:"right",
            width:180,
            scopedSlots: { customRender: 'action' }
          }
        ],
  
        url: {
          list: "/shop/web/shopRefund/getShopRefundList",
          refuseApply: "/shop/web/shopRefund/refuseApply",
          agreedChange: "/shop/web/shopRefund/agreedChange",
          refundMoney:"/shop/web/shopRefund/refundMoney",
        },

      }
    },
    created() {
        this.queryParam.status = this.refundStatus.processing
    },
    computed: {
    
    },
    methods: {
      // 切换状态
      changeRefundStatus(e){
        this.queryParam.status = e
        this.loadData();
      },
       addRotation(){
          this.$refs.modalForm.add();
       },
       modalFormOk(){
          this.loadData();
       },
       // 处理参数
       handleRefuseParam(record){
          let param = {
            refundId:record.id,
            shopInfo:record.shopName,
            specsInfo: null,
            count:record.totalNum,
            money:record.applyMoney,
            applyReason: record.applyReason
          }
          this.refuseParam = param
       },
       // 退款
       refundMoneyShow(record){
          this.handleRefuseParam(record);
          this.refundForm.resetFields();
          this.refundVisible = true;
       },
       refundMoney(){
         let that = this
         this.refundForm.validateFields((err, values) => {
          if (!err) {
              this.modalLoading = true
              values.refundId = that.refuseParam.refundId
              postAction(this.url.refundMoney,values).then((res)=>{
                if(res.code == 200){
                  that.$message.success("操作成功");
                  that.modalFormOk();
                  that.handleCancel();
                }else{
                  that.$message.error(res.message);
                }
              }).finally(() => {
                this.modalLoading = false
              })
          }})
       },
       // 同意换货
       agreedChangeShow(record){
          this.handleRefuseParam(record);
          this.agreedVisible = true;
       },
       agreedChange(){
         let that = this
         this.agreedForm.validateFields((err, values) => {
          if (!err) {
              this.modalLoading = true
              values.refundId = that.refuseParam.refundId
              postAction(this.url.agreedChange,values).then((res)=>{
                if(res.code == 200){
                  that.$message.success("操作成功");
                  that.modalFormOk();
                  that.handleCancel();
                }else{
                  that.$message.error(res.message);
                }
              }).finally(() => {
                this.modalLoading = false
              })
          }})
       },
       // 拒绝申请
       refuseApplyShow(record){
          this.handleRefuseParam(record);
          this.refuseVisible = true;
       },
       refuseApply(){
         let that = this
         this.refuseForm.validateFields((err, values) => {
          if (!err) {
              this.modalLoading = true
              values.refundId = that.refuseParam.refundId
              postAction(this.url.refuseApply,values).then((res)=>{
                if(res.code == 200){
                  that.$message.success("操作成功");
                  that.modalFormOk();
                  that.handleCancel();
                }else{
                  that.$message.error(res.message);
                }
              }).finally(() => {
                this.modalLoading = false
              })
          }})
       },
       handleCancel(){
         this.refuseVisible = false
         this.agreedVisible = false;
         this.refundVisible = false;
       }
    }
  }
</script>
<style scoped>
  @import '~@assets/less/common.less';
</style>