<template>
  <a-modal
    title="上传文件批量发货"
    :width="width"
    :visible="visible"
    :confirmLoading="confirmLoading"
    switchFullscreen
    @ok="submitForm"
    @cancel="handleCancel"
    cancelText="关闭">
   <a-form  :form="form" :label-col="{ span: 17 }" :wrapper-col="{ span: 7}">
        <a-row>
          <a-col :span="24">
             <a-upload
                  v-decorator="['file', { rules: [{ required: true, message: '请选择文件!' }] }]"
                  :file-list="fileList"
                  :before-upload="beforeUpload"
                  accept=".xlsx"
                  :remove="handleRemove"
                >
              <div>
                  <a-button type="primary"> <a-icon type="upload" />上传文件</a-button>
              </div>
            </a-upload>
            <div v-if="alertMsg != null" style="color: red;">
               <a-icon style="margin-left:20px;margin-right:10px;color: red" type="info-circle" />
                {{ alertMsg }}
            </div>
          </a-col>
        </a-row>
      
   </a-form>
  </a-modal>

</template>

<script>

import { httpAction, postAction,uploadAction } from '@/api/manage'
import { validateDuplicateValue,isEmpty } from '@/utils/commonUtil'

// 上报回调
export default {
  name: 'CallClickModal',
  components: {
  },
  props: {
    //表单禁用
    disabled: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data () {
    return {
      title:'修改销量',
      width:600,
      visible: false,
      openLanguage:false,
      fileList: [],
      goodsDetailFileList:[],
      alertMsg: null,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      confirmLoading: false,
      shopGroupFormList:[],
      form: this.$form.createForm(this, { name: 'coordinated' }),
      record:{},
      url: {
        add: "",
        edit: "",
        updateSaleCount:'/shop/web/shop/updateSaleCount'
      }
    }
  },
  computed: {
    
  },
  created () {
  
  },
  methods: {
    show () {
      this.fileList = []
      this.alertMsg = null;
      this.visible = true;
    },
    edit (record) {
      this.visible = true;
    },
    submitForm () {
      this.form.validateFields((err, values) => {
        if (!err) {
            const formData = new FormData();
            this.fileList.forEach(file => {
              formData.append("file", file)  
            });  
            this.batchSendShopByFile(formData);
        }
       })
    },
    batchSendShopByFile(formData){
        let that  = this;
        this.confirmLoading = true
        this.alertMsg = null;
        uploadAction('/shop/web/order/batchSendShopByFile', formData).then((res)=>{
          if(res.code == 200){
            that.$message.success("发货成功");
            that.$emit('ok');
            that.handleCancel();
          }else{
             that.alertMsg = res.message;
          }
        }).finally(() => {
          that.confirmLoading = false;
        })
    },
    close () {
      this.visible = false;
    },
    beforeUpload(file) {
        this.alertMsg = null;
        this.fileList = [];
        this.fileList = [...this.fileList, file];
        return false;
    },
    handleRemove(file) {
        const index = this.fileList.indexOf(file);
        const newFileList = this.fileList.slice();
        newFileList.splice(index, 1);
        this.fileList = newFileList;
    },
    handleCancel () {
      this.close()
    }
  }
}
</script>