<template>
  <a-card :bordered="false">
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
          <a-col :md="6" :sm="12">
            <a-form-item label="微信订单号">
              <a-input
                :allowClear="true" 
                :maxLength="240" 
                placeholder="请输入微信订单号进行搜索"
                v-model="queryParam.transactionId"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="12">
            <a-form-item label="平台订单号">
              <a-input
                :allowClear="true" 
                :maxLength="240" 
                placeholder="请输入平台订单号进行搜索"
                v-model="queryParam.id"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="12">
            <a-form-item label="项目id">
              <a-input
                :allowClear="true" 
                :maxLength="240" 
                placeholder="请输入项目id进行搜索"
                v-model="queryParam.projectId"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="12">
            <a-form-item label="广告id">
              <a-input
                :allowClear="true" 
                :maxLength="240" 
                placeholder="请输入广告id进行搜索"
                v-model="queryParam.promotionId"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="12">
            <a-form-item label="是否使用优惠券">
              <a-select  
                   style="width: 100%" 
                   placeholder="是否使用优惠券"
                   v-model="queryParam.useCoupon"
                   :allowClear="true" >
                  <a-select-option  value="1">
                    是
                  </a-select-option> 
                  <a-select-option  value="0">
                    否
                  </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="12">
            <a-form-item label="回调状态">
              <a-select  
                   style="width: 100%" 
                   placeholder="请选择回调状态"
                   v-model="queryParam.useClick"
                   :allowClear="true" >
                  <a-select-option  value="0">
                    暂未回调
                  </a-select-option> 
                  <a-select-option  value="1">
                    回调成功
                  </a-select-option>
                  <a-select-option  value="2">
                    回调失败
                  </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="12">
            <a-form-item label="订单状态">
              <a-select  
                   style="width: 100%" 
                   placeholder="请选择订单状态"
                   v-model="queryParam.status"
                   :allowClear="true" >
                  <a-select-option  value="1">
                    支付成功
                  </a-select-option> 
                  <a-select-option  value="3">
                    已退款
                  </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="12">
            <a-form-item label="下单时间" >
              <a-range-picker
                style="width: 100%"
                v-model="queryParam.pickerTimeRange"
                :format="dateFormat"
                @change="onDateChange"
              />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="12" v-if="otherDataSource.code">
            <a-form-item label="投手">
              <a-select  
                   style="width: 100%" 
                   placeholder="请选择投手"
                   v-model="queryParam.pitcherCur"
                   :allowClear="true" >
                  <a-select-option  value="UnvaXiaoQiang">
                    小强
                  </a-select-option> 
                  <a-select-option  value="T9ltIkNmKc">
                    坤成
                  </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <span
              style="float: left; overflow: hidden"
              class="table-page-search-submitButtons"
            >
              <a-button type="primary" @click="searchQuery" icon="search"
                >查询</a-button
              >
              <a-button
                type="primary"
                @click="searchReset"
                icon="reload"
                style="margin-left: 8px"
                >重置</a-button
              >
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <!-- 查询区域-END -->

    <!-- 操作按钮区域 -->
    <div class="table-operator">

    </div>

    <!-- table区域-begin -->
    <div>
      <div class="ant-alert ant-alert-info" style="margin-bottom: 16px;">
          <a-descriptions title="数据看板" :column="5" >
            <a-descriptions-item label="已支付金额">
              {{ otherDataSource.actualMoney  }} 元
            </a-descriptions-item>
            <a-descriptions-item label="已支付订单数">
              {{ otherDataSource.actualNum }} 个
            </a-descriptions-item>
            <a-descriptions-item label="退款金额">
              {{ otherDataSource.refundMoney}} 元
            </a-descriptions-item>
            <a-descriptions-item label="退款订单数">
              {{ otherDataSource.refundNum}} 个
            </a-descriptions-item>
            <a-descriptions-item label="实际收益">
              <span style="color: red;">{{ otherDataSource.realMoney }} 元</span>
            </a-descriptions-item>
          </a-descriptions>
      </div>

      <a-table
        ref="table"
        size="middle"
        :scroll="{x:true}"
        bordered
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
         :pagination="ipagination"
        :loading="loading"
        class="j-table-force-nowrap"
        @change="handleTableChange">

       
          <template slot="actualAmount" slot-scope="text, record">
             {{ text }} 元
             <div v-if="record.discountAmount > 0" style="color: red;">(优惠{{ record.discountAmount }} 元)</div>
          </template>
          <template slot="refundMoney" slot-scope="text, record">
             {{ text }} 元
          </template>

          
          <template slot="pitcherCur" slot-scope="text, record">
             <div v-if="record.pitcherCur == 'T9ltIkNmKc'">
                <a-tag color="#5ac725">
                  坤成
                </a-tag>
             </div>
             <div v-else-if="record.pitcherCur == 'UnvaXiaoQiang'">
                <a-tag color="#3c9cff">
                  小强
                </a-tag>
             </div>
             <div v-else>
                <a-tag color="#FF2D2D">
                  {{text}}
                </a-tag>
             </div>
          </template>


         <template slot="useClick" slot-scope="text, record">
            <a-tag color="#5ac725" v-if="record.useClick == 1">
                回调成功
            </a-tag>
            <a-tag color="#FF2D2D" v-else-if="record.useClick == 2">
                回调失败
            </a-tag>
            <a-tag color="#3c9cff" v-else-if="record.useClick == 0">
                暂未回调
            </a-tag>
          </template>

          <template slot="status" slot-scope="text, record">
            <a-tag color="#5ac725" v-if="text == 1">
                支付成功
            </a-tag>
            <a-tag color="#FF2D2D" v-else-if="text == 3">
                已退款
            </a-tag>
          </template>
          

        <span slot="action" slot-scope="text, record">
          <a-popconfirm title="确定回传上报吗?" @confirm="() => pedigreeCallBackClick(record.id)">
              <a>回传上报</a>
          </a-popconfirm>
          <a-divider type="vertical" />
          <a @click="refundPedigreeOrder(record)" v-if="otherDataSource.code">退款</a>
        </span>
      </a-table>
    </div>

    <a-modal
      title="退款"
      :width="800"
      :visible="modalVisible"
      :confirmLoading="confirmLoading"
      switchFullscreen
      @ok="refundMoneyOk"
      @cancel="modalVisible = false"
      cancelText="关闭">
      <a-form  :form="form" :label-col="{ span: 17 }" :wrapper-col="{ span: 7}">
            <a-row>
              <a-col :span="24">
                <a-form-item label="用户实际付款金额" :labelCol="labelCol" :wrapperCol="wrapperCol">
                   {{ record.actualAmount }} 元
                </a-form-item>
              </a-col>
              <a-col :span="24">
                <a-form-item label="设置退款金额" :labelCol="labelCol" :wrapperCol="wrapperCol">
                  <a-input-number :max="999999" :min="0.01" :precision="2"
                       v-decorator="['refundMoney', { rules: [{ required: true, message: '设置设置退款金额!' }] }]"
                       placeholder="设置设置退款金额" style="width: 100%" />
                </a-form-item>
              </a-col>
            </a-row>
      </a-form>
    </a-modal>
  </a-card>
</template>
 
<script>

  import '@/assets/less/TableExpand.less'
  import { mixinDevice } from '@/utils/mixin'
  import { CustomerShopMixin } from '@/mixins/CustomerShopMixin'
  import { getAction, postAction,uploadAction } from '@/api/manage'

  // 轮播图管理
  export default {
    name: 'RotationList',
    mixins:[CustomerShopMixin, mixinDevice],
    components: {
    },
    data () {
      return {
        description: 'RotationList管理页面',
        dateFormat:'YYYY-MM-DD',
        modalVisible: false,
        confirmLoading: false,
        form: this.$form.createForm(this, { name: 'coordinated' }),
        refundParam: {},
        record:{},
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
        // 表头
        columns: [
          
          {
            title: '微信订单号',
            dataIndex: 'transactionId',
            align:"center",
            ellipsis: true,
            width:180,
          },
          {
            title: '下单时间',
            dataIndex: 'createTime',
            align:"center",
            ellipsis: true,
            width:180,
          }, {
            title: '订单状态',
            dataIndex: 'status',
            scopedSlots: { customRender: 'status' },
            align:"center",
            ellipsis: true,
            width:180,
          },
          {
            title: '支付金额',
            dataIndex: 'actualAmount',
            scopedSlots: { customRender: 'actualAmount' },
            align:"center",
            ellipsis: true,
            width:160,
          },
          {
            title: '退款金额',
            dataIndex: 'refundMoney',
            scopedSlots: { customRender: 'refundMoney' },
            align:"center",
            ellipsis: true,
            width:140,
          },
          {
            title: '搜索姓氏',
            dataIndex: 'searchName',
            align:"center",
            width:180,
            ellipsis: true,
          },
          {
            title: '用户预留电话',
            dataIndex: 'phone',
            align:"center",
            width:180,
            ellipsis: true,
          },
          {
            title: '平台订单号',
            dataIndex: 'id',
            align:"center",
            ellipsis: true,
            width:180,
          },
          {
            title: '投手',
            dataIndex: 'pitcherCur',
            scopedSlots: { customRender: 'pitcherCur' },
            align:"center",
            width:180,
            ellipsis: true,
          },
          {
            title: '项目id',
            dataIndex: 'projectId',
            align:"center",
            width:180,
            ellipsis: true,
          },
          {
            title: '广告id',
            dataIndex: 'promotionId',
            align:"center",
            width:180,
            ellipsis: true,
          },
          {
            title: '是否回调',
            dataIndex: 'useClick',
            scopedSlots: { customRender: 'useClick' },
            align:"center",
            width:180,
            ellipsis: true,
          },
          {
            title: '回调信息',
            dataIndex: 'clickMsg',
            align:"center",
            width:180,
            ellipsis: true,
          },
          {
            title: '回调标识',
            dataIndex: 'clickId',
            align:"center",
            width:180,
            ellipsis: true,
          },
          {
            title: '操作',
            dataIndex: 'action',
            align:"center",
            fixed:"right",
            width:147,
            scopedSlots: { customRender: 'action' }
          }
        ],
        url: {
          list: "/shop/web/electronTools/getPedigreeOrderList",
        },

      }
    },
    created() {
    },
    computed: {
    
    },
    methods: {
       addRotation(){
          this.$refs.modalForm.add();
       },
       modalFormOk(){

       },
       onDateChange: function (value, dateString) {
          this.queryParam.startTime=dateString[0];
          this.queryParam.endTime=dateString[1];
       },
       refundPedigreeOrder(record){
          let that = this;
          let param = {
             id: record.id,
             status: 3
          }
          this.refundParam = param;
          this.record = record;
          this.$nextTick(() => {
            setTimeout(() => {
              this.form.setFieldsValue({
                 refundMoney: record.actualAmount,
              })
            })
        });
          this.modalVisible = true
       },
       refundMoneyOk(){
          let that = this;
          this.confirmLoading = true
          let param = this.refundParam;
          this.form.validateFields((err, values) => {
          if (!err) {
                param.refundMoney = values.refundMoney
                postAction("/shop/web/electronTools/refundPedigreeOrder",param).then((res)=>{
                if(res.code == 200){
                  that.$message.success("退款成功");
                  that.modalVisible = false
                  that.loadData();
                }else{
                  that.$message.error(res.message);
                }
              }).finally(() => {
                that.confirmLoading = false
              })
            }
          })
          
       },
       pedigreeCallBackClick(id){
          let that = this;
          this.loading = true
          let param = {
             id: id
          }
          postAction("/shop/web/electronTools/pedigreeCallBackClick",param).then((res)=>{
            if(res.code == 200){
              that.$message.success("操作成功");
              that.loadData();
            }else{
              that.$message.error(res.message);
            }
          }).finally(() => {
            this.loading = false
          })
       }
    }
  }
</script>
<style scoped>
  @import '~@assets/less/common.less';
</style>