import { render, staticRenderFns } from "./ShopCouponModal.vue?vue&type=template&id=115a2eff&"
import script from "./ShopCouponModal.vue?vue&type=script&lang=js&"
export * from "./ShopCouponModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports