<template>
    <a-modal
      :title="title"
      :width="width"
      :visible="visible"
      switchFullscreen
      @ok="submitForm"
      @cancel="handleCancel"
      :confirmLoading="confirmLoading"
      cancelText="关闭">
          <a-form  :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16}">
            <a-row>
              <a-col :span="24">
                <a-form-item label="触发时机">
                  <a-radio-group  v-decorator="['couponHappen', { rules: [{ required: true, message: '请选择触发时机！' }] }]" >
                      <a-radio :value="1" @click="changeLiveManyPage(1)">
                        进入页面触发
                      </a-radio>
                      <a-radio :value="2" @click="changeLiveManyPage(2)">
                        离开页面
                      </a-radio>
                      <a-radio :value="-1" @click="changeLiveManyPage(-1)">
                        下架优惠券
                      </a-radio>
                  </a-radio-group>
                </a-form-item>
              </a-col>
              <a-col :span="24" v-if="lviePage == 1">
                <a-form-item label="优惠券金额(整数)">
                  <a-input-number :max="99999" :min="0" :precision="0"
                           v-decorator="['couponMoney', { rules: [{ required: true, message: '请输入优惠券金额,必须是整数!' }] }]"
                           placeholder="请输入优惠券金额,必须是整数" style="width: 100%" />        
                    <a-icon style="margin-left:20px;margin-right:10px;color:#EA7500" type="info-circle" />每次设置都会覆盖之前设置的优惠券,如果想下架优惠券,请设置0        
                  </a-form-item>
              </a-col>
              <a-col :span="24" v-if="lviePage == 2">
                <a-form-item label="离开次数">
                  <a-select  
                     style="width: 100%" 
                     placeholder="请选择离开次数"
                     v-decorator="['leaveNum', { rules: [{ required: true, message: '请选择离开次数!' }] }]"
                     @change="changeLeaveNum"
                     >
                      <a-select-option  :value="1">
                        1次
                      </a-select-option> 
                      <a-select-option  :value="2">
                        2次
                      </a-select-option>
                      <a-select-option  :value="3">
                        3次
                      </a-select-option>
                      <a-select-option  :value="4">
                        4次
                      </a-select-option>
                      <a-select-option  :value="5">
                        5次
                      </a-select-option>
                  </a-select>    
                </a-form-item>
              </a-col>
              <a-col :span="24" v-if="lviePage == 2" >
                <a-form-item :label="'第'+item+'次离开优惠金额'" v-for="item in realLeaveNum" :key="item">
                  <a-input-number :max="99999" :min="1" :precision="0"
                           v-decorator="['couponMoney' + item, { rules: [{ required: true, message: '请输入优惠券金额,必须是整数!' }] }]"
                           placeholder="请输入优惠券金额,必须是整数" style="width: 100%" />  
                </a-form-item>
                
              </a-col>
            </a-row>
          </a-form>
    </a-modal>  

</template>

<script>

  import { postAction, getAction } from '@/api/manage'
  import { validateDuplicateValue } from '@/utils/commonUtil'
  export default {
    name: 'ShopCouponModal',
    components: {
    },
    data () {
      return {
        title:'',
        width:800,
        visible: false,
        openLanguage:false,
        confirmLoading: false,
        lviePage:false,
        record:{},
        realLeaveNum:[1],
        form: this.$form.createForm(this, { name: 'coordinated' }),
        url: {
          add: "",
          edit: "",
          setShopCoupon:'/shop/web/shop/setShopCoupon'
        }
      }
    },
    computed: {
    
    },
    created () {
    },
    methods: {
      show (record) {
        console.log(record)
        this.form.resetFields();
        this.record = record;
        this.title = "设置优惠券: " + record.goodsNameZh;
        let couponHappen = record.couponHappen
        if(couponHappen == null || couponHappen == -1){
           this.$nextTick(() => {
              setTimeout(() => {
                this.form.setFieldsValue({
                  couponHappen: -1,
                })
              })
           });
        }else if(couponHappen == 1){
           this.$nextTick(() => {
              setTimeout(() => {
                this.form.setFieldsValue({
                  couponHappen: 1,
                  couponMoney: record.couponMoney
                })
              })
           });
        }else if(couponHappen == 2){
           this.realLeaveNum = record.leaveNum
           let setValue = {
              couponHappen: 2,
              leaveNum: record.leaveNum,
           }
           let leaveMoneyArray = record.leaveMoneyList
          
           for(let i in leaveMoneyArray){
               let leave = leaveMoneyArray[i];
               let index = parseInt(i) + 1
               setValue['couponMoney' + index] = leave
           }
           console.log(setValue)
           this.$nextTick(() => {
              setTimeout(() => {
                this.form.setFieldsValue(setValue)
              })
           });
        }
        this.lviePage = couponHappen
        this.visible = true;
      },
      edit (record) {
        this.visible = true;
        this.title = "新增分组"
      },
      submitForm () {
        let that = this;
        this.form.validateFields((err, values) => {
          if (!err) {
                let leaveMoney = "";
                let leaveNum = "";
                let couponMoney = 0;
                if(values.couponHappen == 2){
                  let leaveMoneyArray = [];
                  for(let h in values){
                    if(h.indexOf('couponMoney')>=0){
                      leaveMoneyArray.push(values[h]);
                    }
                  }
                  leaveMoney = leaveMoneyArray.join(',')
                  leaveNum = values.leaveNum
                }
                if(values.couponMoney){
                   couponMoney = values.couponMoney
                }
                let param = {
                  goodsId : that.record.id,
                  couponMoney: couponMoney,
                  couponHappen:  values.couponHappen,
                  leaveMoney: leaveMoney,
                  leaveNum: leaveNum
                }
              that.confirmLoading = true
              postAction(this.url.setShopCoupon,param).then((res)=>{
                if(res.code == 200){
                  that.$message.success("设置成功");
                  that.handleCancel()
                  that.$emit('ok');
                }else{
                  that.$message.error(res.message);
                }
              }).finally(() => {
                that.confirmLoading = false;
              })
          }
         })
      },
      changeLiveManyPage(e){
        this.lviePage =  e;
      },
      changeLeaveNum(e){
        let array = [];
        for(let i=1;i<=e;i++){
          array.push(i)
        }
        this.realLeaveNum = array;
      },
      handleCancel () {
        this.visible = false;
      }
    }
  }
</script>