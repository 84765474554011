<template>
  <a-modal
    title="手动上报回传"
    :width="width"
    :visible="visible"
    :confirmLoading="confirmLoading"
    switchFullscreen
    @ok="callBackClick"
    @cancel="handleCancel"
    cancelText="关闭">
   <a-form  :form="form" :label-col="{ span: 17 }" :wrapper-col="{ span: 7}">
        <!-- <a-row>
          <a-col :span="24">
            <a-form-item label="项目id" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input :maxLength="200" v-decorator="['clickProjectId', { rules: [{ required: true, message: '请输入项目id!' }] }]"  
                  placeholder="请输入项目id"  ></a-input>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="广告id" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input :maxLength="200" v-decorator="['clickAdvertId', { rules: [{ required: true, message: '请输入广告id!' }] }]"  
                  placeholder="请输入广告id"  ></a-input>
            </a-form-item>
          </a-col>
        </a-row> -->
        确定要上报吗?
   </a-form>
  </a-modal>

</template>

<script>

import { httpAction, postAction,uploadAction } from '@/api/manage'
import { validateDuplicateValue,isEmpty } from '@/utils/commonUtil'

// 上报回调
export default {
  name: 'CallClickModal',
  components: {
  },
  props: {
    //表单禁用
    disabled: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data () {
    return {
      title:'修改销量',
      width:600,
      visible: false,
      openLanguage:false,
      fileList: [],
      goodsDetailFileList:[],

      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      confirmLoading: false,
      shopGroupFormList:[],
      form: this.$form.createForm(this, { name: 'coordinated' }),
      record:{},
      url: {
        add: "",
        edit: "",
        updateSaleCount:'/shop/web/shop/updateSaleCount'
      }
    }
  },
  computed: {
    
  },
  created () {
  
  },
  methods: {
    show (param) {
      this.record = param;
      this.visible = true;
    },
    edit (record) {
      this.visible = true;
    },
    submitForm () {
      this.form.validateFields((err, values) => {
        if (!err) {
            let param = {
              orderId : this.record.id,
              clickProjectId: values.clickProjectId,
              clickAdvertId: values.clickAdvertId
            }
            this.callBackClick(param);
        }
       })
    },
    callBackClick(){
        let that = this
        let param = {
           orderId : this.record.id,
        }
        this.confirmLoading = true
        postAction('/shop/web/order/callBackClick',param).then((res)=>{
          if(res.code == 200){
            that.$message.success("上报成功");
            that.$emit('ok');
            that.handleCancel();
          }else{
            that.$message.error(res.message);
          }
        }).finally(() => {
          that.confirmLoading = false;
        })
    },
    close () {
      this.visible = false;
    },
    handleCancel () {
      this.close()
    }
  }
}
</script>